const reviews = [
  {
    id: 1,
    name: 'Garima Sachdeva',
    job: 'Working professional at Eveready Industries',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      "Its an excellent course which is really improving my speaking & writing skills. Though its been only few weeks since I joined this course but I can feel the difference in the way I have started communicating. Thank you Pious Classes! Regular practice sessions are really churning out the best in me. I think it is a must-take course for those who are interested in improving there overall english speaking, writing & public speaking skills.",
  },
  {
    id: 2,
    name: 'Anandhi A',
    job: 'IT Company',
    image:
      "https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png",
    text:
      'I had a good experience here. Its was really helpful for me to overcome my fear and build confidence. Hope I will implement in my daily routine moving forward. Thanks for the opportunity!',
  },
  {
    id: 3,
    name: 'Shweta Puri',
    job: 'PnC',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'It\'s a good class. I improved my English knowledge because of this class. Will definitely recommend this class to my friends and families ',
  },
  {
    id: 4,
    name: 'Ashish Sharma',
    job: 'Student',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'I\'ve benefited a lot by pursuing this course. Small pronunciation and word stress has been cleared. I liked and enjoyed the journey of learning I would suggest non-English speakers to take up this course to enhance their English language skill.',
  },
  {
    id: 5,
    name: 'Prachi Tapase',
    job: 'Student at Institute of hotel management and catering technology Bhopal ',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Jaanvi mam,  you are such a great teacher,  mentor,  and a coolest English teacher,  since i joined your classes, I\'m getting more fluent in English day by by.  Thank you so much for everything. Lots of love ❣️',
  },
  {
    id: 6,
    name: 'Garima Sachdeva',
    job: 'Eveready Industries',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Its an excellent course which is really improving my speaking & writing skills. Though its been only few weeks since I joined this course but I can feel the difference in the way I have started communicating. Thank you Pious Classes!Regular practice sessions are really churning out the best in me. I think it is a must-take course for those who are interested in improving there overall english speaking, writing & public speaking skills.',
  },
  {
    id: 7,
    name: 'Meenakshi kumari',
    job: 'Student at C. S. R.U bed college',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'I think this is a great platform to learn and boost one\'s personality. Janavi ma\'am is so polite and humble with the students to make class very interactive. I love Pious Class and happy to be a part of this initiative.',
  },
  {
    id: 8,
    name: 'Divya',
    job: 'IND Sanitation ',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Jaanvi mam is really a nice and kind  person I have ever met also she have best teaching skills. The way she teaches is commendable.',
  },
  {
    id: 9,
    name: 'Pradnya mali ',
    job: 'A I Jain and associates',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Pious Classes is incredible ♥️',
  },
  {
    id: 10,
    name: 'Madiha Alauddin',
    job: 'Student at DPS',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Best teaching experience',
  },
  {
    id: 11,
    name: 'Sagar Kumar',
    job: 'Student at Delhi Technological University',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Blessed to have a mentor like you <3. Daily quest on Instagram and different reels are so valuable. Classes are upto the point that we need.',
  },
  {
    id: 12,
    name: 'Pooja Rastogi',
    job: 'White owl',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Really enjoyed myself in the classes they are very engaging and open to all. Made some good friends. Had a great time.',
  },
  {
    id: 13,
    name: 'Vinay B S',
    job: 'Mindtree',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Tutors have great knowledge and the courses are well structured.',
  },
  {
    id: 14,
    name: 'Simaranjit Kaur',
    job: 'ELC kids',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      '5 star ⭐️ service had a great 5 months great experience moreover, great communication with my mentor. ',
  },
  {
    id: 15,
    name: 'Vivek Mishra',
    job: 'Lead School',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Fantastic. This classes is very beneficial for me in my English improvement.',
  },
  {
    id: 16,
    name: 'Mala srivastava ',
    job: 'Yoga teacher',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Dear ma\'am, This online classes are very interesting. She clear our doubt very easily. I am very glad to take this class.',
  },
  {
    id: 17,
    name: 'Parthivi Mehta ',
    job: 'Kamala nehru college,  Delhi University ',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Best classes where u can learn how to express yourself,  learn how to pronounce,  where u can develop ur personality.  Being a part of such classes I learned a lot.  My communication,  confidence level is on next level .',
  },
  {
    id: 18,
    name: 'K Prashanth Kumar',
    job: 'APPC',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'I would like to thank you Jaanvi Taneja for your continuous effort to help students like us in improving vocabulary and build the confidence on our public speaking  skills. While attending the session I can sense that every student wanted to attend the class without fail even though they are outside the home and they want to be a part of the classes. And I would like to appreciate the time to time feedback on the improvements of every individual and suggesting them on the places to focus on, gives the personal touch that you have with your students. After attending couple of classes it felt like I have taken a right decision to join Pious classes, as it seemed to me a platform that you have created that everyone can share their thoughts without feeling they are judged. Overall I am felling happy to be the part of pious classes and they are magnificent.',
  },
  {
    id: 19,
    name: 'Rupali Gupta',
    job: 'St.Xavier’s College ',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'The classes are really influencing motivating or amazing',
  },
  {
    id: 20,
    name: 'Manju Chowdhary',
    job: 'N/A',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'The classes are well designed and focused on students. The tips given in the class are useful. Moreover,  students get to listen and learn from other student mistakes which is added beneficial for everyone and also, better content from the one who are fluent speaker.  The weaknesses are identified and covered in the class to improve it.',
  },
  {
    id: 21,
    name: 'Harshita Jhawar',
    job: 'Mgkvp',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Ever since I have joined the classes, I have increased on my confidence level and also my vocabulary. Also, Jaanvi is the most kind hearted person I have come across. She corrects my mistakes in the polite manner. That\'s the gesture students expect. Thankyou for starting these classes because I wouldn\'t be there where I\'m today. I would send a message to people who are struggling with the English should without a second thought join these classes, it will help you be a better speaker and listener.' 
  },
  {
    id: 22,
    name: 'ISHITA AGRAWAL',
    job: 'SIES COLLEGE OF ARTS , COMMERCE AND SCIENCE',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Really like studying with Janvi ma\'am . She makes the class very interesting and interacting so it is really helpful for people who wants to learn how to speak English',
  },
  {
    id: 23,
    name: 'Zainab Jahangir',
    job: 'Invertis university',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Since i have joined your classes i have saw a slight change in myself as my confidence in speaking English got a little boost .I have learned so many new things in the class. This platform is just perfect for all who want to upgrade them selves and confront their fear of speaking in English.',
  },
  {
    id: 24,
    name: 'Prachi agrawal',
    job: 'Bio state Inc.',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'Best online environment with great teacher faculty. Great english learning platform.',
  },
  {
    id: 25,
    name: 'Amit Khokhar',
    job: 'Government P.G college Jind , Haryana',
    image:
      'https://www.clker.com/cliparts/q/n/7/N/o/H/language-arts-md.png',
    text:
      'I have no words how to explain but the sessions conducted by Pious classes are awesome . very interactive . Great learning of English speaking .i give 5 star out of  5.',
  }
];

export default reviews;
