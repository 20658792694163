import React from 'react';
import FreeForm from "./FreeForm";
import NavbarBatches from "../Batches/navbarBatches";
import Whatsappfooter from "../Footer/WhatsappFoot";
import Footer from "../Footer/footer";
import ScrollToTop from "../ScrollToTop";

const DemoBatch = () => {
  return (
    <div>
      <ScrollToTop/>
      <NavbarBatches/>
      <FreeForm/>
      <Whatsappfooter/>
      <Footer/>
    </div>
  );
};

export default DemoBatch;
