import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './components/Home/home';
import SigninPage from './components/Signin/signin';
import BeginnerBatch from "./components/Batches/BeginnerBatch";
import AdvancedBatch from "./components/Batches/AdvancedBatch";
import OneOneBatch from "./components/Batches/OneOneBatch";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGa from 'react-ga';
import {useEffect} from 'react';
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import TermsAndCondition from "./components/Footer/TermsAndCondition";
import RefundAndCancellationPolicy from "./components/Footer/RefundAndCancellationPolicy";
import DemoBatch from "./components/FreeForm/DemoBatch";

function App() {

  useEffect(() => {
    ReactGa.initialize('UA-219969120-1');
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, [])

  let login = true;
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact/>
        <Route path='/signin' component={SigninPage} exact/>
        <Route path='/beginner' component={BeginnerBatch} exact/>
        <Route path='/advance' component={AdvancedBatch} exact/>
        <Route path='/oneone' component={OneOneBatch} exact/>
        <Route path='/demo' component={DemoBatch} exact/>
        <Route path='/privacy-policy' component={PrivacyPolicy} exact/>
        <Route path='/terms-and-condition' component={TermsAndCondition} exact/>
        <Route path='/refund-and-cancellation-policy' component={RefundAndCancellationPolicy} exact/>
        <Route path='/thank-you-demo' component={RefundAndCancellationPolicy} exact/>
      </Switch>
    </Router>
  );
}

export default App;
