import React, {useState} from 'react';
import {Form, Alert} from "react-bootstrap";
import {Buttonbutton} from "../Batches/cssBatches";
import displayRazorpay from "../Razorpay/razorpay";
import Loading from 'components/Loading/loading';
import ReactGa from 'react-ga';

const initialState = {
  name: '',
  email: '',
  phone: '',
  batch: 'Mon, Wed and Fri',
  time1: '',
  time2: '',
  time3: '',
  days: '',
  months: '1'
}

const CustomeForm = (props) => {

  const [newUser, setNewUser] = useState(initialState);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const {name, phone, email, batch, time1, time2, time3, days, months} = newUser;

      const action_string = `Name: ${name}, Phone: ${phone}, mail: ${email.match(
        /^([^@]*)@/)[1]}, Batch: ${batch}, Days: ${days}, month: ${months}`;

      ReactGa.event({
        category: 'Paying Customer',
        action: action_string,
      })

      displayRazorpay({
        name,
        phone,
        email,
        batch,
        time2,
        time3,
        days,
        months,
        topLine: props.heading,
        id: (props.batch + '36'),
        setIsLoading
      });

    }
    setValidated(true);
  };

  const handleOnChange = (e) => {
    const {name, value} = e.target;

    setNewUser({...newUser, [name]: value});
  };


  return (
    <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newUser.name}
          onChange={handleOnChange}
          required
          placeholder="For e.g. Jaanvi Taneja"/>
        <Form.Control.Feedback type="invalid">
          Please provide a name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          type="email"
          name="email"
          value={newUser.email}
          onChange={handleOnChange}
          placeholder="Enter email"/>
        <Form.Control.Feedback type="invalid">
          Please provide correct email.
        </Form.Control.Feedback>

      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Phone No.</Form.Label>
        <Form.Control
          required={true}
          type="phone"
          name="phone"
          value={newUser.phone}
          onChange={handleOnChange}
          placeholder="Enter your phone number"/>
        <Form.Control.Feedback type="invalid">
          Please provide your contact number.
        </Form.Control.Feedback> </Form.Group>

      <div key={`inline-radio-3`}
           className={"mb-4"}>
        <h6>Batch Timings available: </h6>
        <Form.Check
          inline
          label="8-9 p.m"
          name="time2"
          type='radio'
          value="8-9 p.m"
          onChange={handleOnChange}
          required={true}
          feedback="Required"
          feedbackType="invalid"
        />
      </div>

      <Buttonbutton duration={500}
                    exact='true'
                    offset={-80}
                    primary={1}
                    dark={0}
                    dark2={1}
                    type="submit"
      >{
        isLoading ?
          <Loading type={'bars'}/> :
          'Pay Rs. 9999'
      }
      </Buttonbutton>
    </Form>
  );
};

export default CustomeForm;
