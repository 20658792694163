import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import displayRazorpay from '../Razorpay/razorpay.js'
import ReactGa from 'react-ga';
import { useEffect } from 'react';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Icon, NotAvail, Avail, MediumHeading, Buttonbutton
} from './cssBatches'
import {Button, Alert, Badge} from "react-bootstrap";
import MyVerticallyCenteredModal from "../Modal/modal";
import MyFeesPlanModal from "../Modal/feesPlanModal";


const Batches = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description1, description2, description3, description4, description5, description6, description7
                   , availBatches, notAvailBatches, buttonLabel, alt, img, primary, dark, dark2, note }) => {

  const [feesModalShow, setFeesModalShow] = React.useState(false);
  const [enrollModalShow, setEnrollModalShow] = React.useState(false);

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Alert variant='dark' style={{fontSize: '12px'}}><div dangerouslySetInnerHTML={{ __html: description1}}/></Alert>
                <Alert variant='info' style={{fontSize: '12px'}}><div dangerouslySetInnerHTML={{ __html: description2}}/></Alert>
                <Alert variant='dark' style={{fontSize: '12px'}}><div dangerouslySetInnerHTML={{ __html: description3}}/></Alert>
                <Alert variant='info' style={{fontSize: '12px'}}><div dangerouslySetInnerHTML={{ __html: description4}}/></Alert>
                <Alert variant='dark' style={{fontSize: '12px'}}><div dangerouslySetInnerHTML={{ __html: description5}}/></Alert>
                {
                  description6 != null?<Alert variant='info' style={{fontSize: '12px'}}><div dangerouslySetInnerHTML={{ __html: description6}}/></Alert>:<></>
                }
                
                <BtnWrap>
                    <Button variant="outline-primary" size="lg" onClick={() => {
                      ReactGa.event({
                        category: id,
                        action: 'Fees Plan'
                      })
                      setFeesModalShow(true)}
                    }>
                      Fees Plan
                    </Button>
                    <MyFeesPlanModal
                      batch= {id}
                      heading = {topLine}
                      show={feesModalShow}
                      note={note}
                      onHide={() => setFeesModalShow(false)}
                    />

                      <Button variant="success" size="lg" onClick={() => {
                        ReactGa.event({
                          category: id,
                          action: 'Enroll'
                        })
                        setEnrollModalShow(true)}
                        }>
                        Enroll
                      </Button>

                      <MyVerticallyCenteredModal
                        batch= {id}
                        heading = {topLine}
                        show={enrollModalShow}
                        onHide={() => setEnrollModalShow(false)}
                      />
                </BtnWrap>

              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Batches
