import React, {useState} from 'react'
import Video from '../../videos/video6275902463132304516.mp4'
import {
  ArrowForward,
  ArrowRight,
  Button,
  FreeButton,
  HeroBg,
  HeroBtnWrapper,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  VideoBg
} from './cssHero'

const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg autoPlay loop muted poster="../../images/Pious1.png" src={Video} type='video/mp4'/>
      </HeroBg>
      <HeroContent>
        <HeroH1> Just a language</HeroH1>
        <HeroP>We are an online English learning platform which provides courses covering Public Speaking, Personality
          Development, Interview Skills, Soft Skills, Grammar and Vocabulary </HeroP>
        <HeroBtnWrapper>
          <Button to='Course' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'
                  duration={500} exact='true' offset={-80}>
            Explore Course {hover ? <ArrowForward/> : <ArrowRight/>}
          </Button>

          <FreeButton to='/demo' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'
                      duration={500} exact='true' offset={-80}>
            Book Free Demo{hover ? <ArrowForward/> : <ArrowRight/>}
          </FreeButton>

        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
