import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const RefundAndCancellationPolicy =
  () => {
    const {pathname} = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname]);
    return (
      <div>

        <h1>CANCELLATIONS AND REFUND POLICY
        </h1>
        <p>Cancellations: As a general rule, all sales made on PIOUS CLASSES are final and you shall not be entitled
          to cancel your order once you have made the payment. PIOUS CLASSES reserves the sole right
          to cancel any order as per our discretion in case (i) we are unable to deliver the order in a satisfactory
          manner and/ or (ii) the user tries to take advantage of the system which violates the Terms of Use. PIOUS
          CLASSES
          will ensure that any communication of cancellation of an order or any applicable refund will be made
          within a reasonable period of time.

        </p>
        <p>Refunds: You shall be entitled to a refund only if PIOUS CLASSES is unable to provide you the services i.e.,
          online classes, product, etc. For
          refund requests please email to piousclasses16@gmail.com for Online Classroom Program within 1 week of the
          date of purchase. All refunds will be processed on a prorated
          basis, depending on the service already delivered by PIOUS CLASSES. Refunds will be done directly to the
          original payment mode within seven working days of finalization of the claim.
        </p>
      </div>
    );
  };

export default RefundAndCancellationPolicy;
