import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const Forms = () => {
    const [feedback, setFeedback] = useState('');

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setFeedback(value);
    };

    return (
        <Form>
            <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
            >
                <Form.Label
                    style={{
                        fontWeight: 'bold',
                        color: 'darkblue',
                        marginTop: '20px',
                    }}
                >
                    Any Question? Please contact us, our customer support is 24x7.
                </Form.Label>
                <Form.Control
                    value={feedback}
                    onChange={handleOnChange}
                    as="textarea"
                    placeholder="Enter your query"
                    style={{ height: '300px' }}
                />
            </Form.Group>
            <Button
                href={
                    'https://api.whatsapp.com/send?phone=919560990481&text=Hi%20pious%20classes%2C%20hope%20you%20are%20doing%20good.%20I%27ve%20got%20feedback%2Fquery%20for%20you%3A-%20' +
                    `${feedback}` +
                    '%20%0A'
                }
                variant="primary"
                type="submit"
                style={{ marginTop: '20px' }}
            >
                Submit
            </Button>
        </Form>
    );
};

export default Forms;
