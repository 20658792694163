import React, {useState} from 'react'
import Footer from '../Footer/footer';
import Hero from '../Hero/hero'
import Navbar from '../Navbar/navbar'
import Courses from '../Courses/courses';
import Sidebar from '../SideBar/sidebar'
import ContactUs from "../ContactUs/contactUs";
import Whatsappfooter from "../Footer/WhatsappFoot";
import ControlledAccordions from "../Faq/faq";
import Review from "../Reviews/reviews";
import AboutUs from "../AboutUs/aboutus";



const Home = () => {
  const [isOpen, setIsOpen] = useState(false)


  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Hero />
      <AboutUs/>
      <Courses />
      <Review/>
      <ControlledAccordions/>
      <ContactUs />
      <Whatsappfooter />
      <Footer />

    </>
  )
}

export default Home
