import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const TermsAndCondition = () => {
  const {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname]);
  return (
    <div>

      <h1>TERMS OF USE</h1>
      <p>The present Terms of Use document is being published in accordance with the provisions of the Information
        Technology Act, 2000 and other applicable Rules thereunder, including but not limited to the Information
        Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 ( “IT Rules” )</p>
      <p>Welcome to the PIOUS CLASSES - piousclasses.com ( "the Website" ). The Website hereinafter be referred to as
        "the Platform" . For the purposes of this Terms of Use, accessing of the Platform together with any study
        material
        made available or uploaded therein or downloaded, embedded therefrom shall hereinafter be collectively be
        referred to as the "Services" .</p>

      <h2>INTRODUCTION</h2>
      <ol>
        <li>By using or accessing the Platform in any way, including registering on the Platform, using, viewing,
          sharing, embedding and/or downloading any Services, you agree to be bound by the Terms of Use set forth
          herein. By accepting these Terms of Use, you also accept and agree to be bound by PIOUS CLASSES Policies,
          including but not limited to the PIOUS CLASSES Privacy Policy and the PIOUS CLASSES Copyright Policy.
        </li>
        <li>If you do not understand the present Terms of Use or do not agree with/accept any part thereof, you should
          immediately discontinue using or accessing the Platform. Your use and/or continued use of the Platform, as the
          case may be, amounts to express consent by you to the terms of this Terms of Use as well as other PIOUS
          CLASSES Policies.
        </li>
      </ol>

      <h2>ELIGIBILITY</h2>
      <ol>
        <li>
          By agreeing to these Terms of Use, you represent that you are legally competent to enter into an agreement and
          provide consent to these Terms of Use. You further represent that
          <ol>
            <li>You are of sound mind.
            </li>
            <li>By agreeing to these Terms of Use, you represent that you are legally competent to enter into an
              agreement and provide consent to these Terms of Use.
            </li>
            <li>Are not prohibited from entering into a legally binding contract as per applicable laws.
            </li>
          </ol>
        </li>
        <li>In case the Platform is being accessed, and the Services are being availed for the benefit of a minor, you
          expressly confirm that you are legally competent to provide consent on behalf of such a minor and that the
          minor’s use of the Platform and/or the Services shall be subject to the Terms of Use.

        </li>
      </ol>

      <div>
        <h2>YOUR ACCOUNT
        </h2>

        <p>You can become a registered user of the Platform by setting up a password-protected account with us. In order
          to set up such an account, you will be required to enter the details of your registered email id and password
          or you may login using the account details of any partner website. By setting up an account, you agree to
          accept any and all responsibility for any and all activities that occur under your account. By setting up the
          account, you further agree to the contents of the Privacy Policy.

        </p>
        <p>PIOUS CLASSES reserves the right and sole discretion to refuse access to the Platform, terminate any
          account, remove or restrict any content, at any time, with or without notice to you in case we notice any
          illegal activity on or from account or if we have reason to believe that any information provided by you is
          untrue, inaccurate, outdated or incomplete.

        </p>
      </div>


      <div>
        <h2>USER CONTENT
        </h2>

        <p>PIOUS CLASSES provides its users with the option of creating posts and/or upload content on the Platform (
          "User Content" ). When you submit or upload any content on the Platform, you represent and warrant that:

        </p>
        <ol>
          <li>You own all copyright in the content, or if you are not the owner, that you have permission to use the
            content, and that you have all the rights and permissions necessary to permit uploading, displaying,
            publishing, sharing, storing, hosting, reproduction, downloading, the transmission of such content.
          </li>
          <li>The content you upload will not infringe the intellectual property rights or other rights of any person or
            entity, including but not limited to copyright, moral rights, trademark, patent or rights of privacy or
            publicity.
          </li>
          <li>Your use of the Platform will comply with all applicable laws, rules and regulations.
          </li>
          <li>The content does not contain material that is harmful to children, defames or vilifies any person, people,
            races, religion or religious group, misleads the addressee, if any, about the origin of the content or is
            false or misleading in nature but may reasonably be perceived as a fact and the content is not obscene,
            pornographic, paedophilic, indecent, insulting or harassing including on the basis of gender, threatening,
            harmful, invasive of privacy including bodily privacy or publicity rights, abusive, inflammatory, published
            to mislead or harass any person for financial gain or to cause any injury to any person, related to or
            encouraging money laundering or gambling or otherwise objectionable or inconsistent with or contrary to the
            laws in force.
          </li>
          <li>The content is not misleading and deceptive and does not offer or disseminate fraudulent goods, services,
            schemes, or promotions.
          </li>
          <li>Your use of the Platform including by way of posting of content on the Platform shall not impersonate any
            other person.
          </li>
          <li>The content does not contain material that threatens the unity, integrity, defence, security or
            sovereignty of India, friendly relations with foreign States or public order nor does the content contain
            material that causes incitement to the commission of any cognisable offence or prevents investigation of any
            offence or is insulting to any other nation;
          </li>
          <li>The content or information uploaded or submitted on the Platform does not contain any software virus or
            any other computer code, file or program designed to interrupt, destroy or limit the functionality of any
            computer resource.
          </li>

        </ol>
      </div>


      <div>
        <h2>RESTRICTION AND TERMINATION OF USE
        </h2>

        <p>PIOUS CLASSES may block, restrict, disable, suspend or terminate your access to all or part of the free
          Services offered on the Platform, at any time in PIOUS CLASSES's sole discretion, without prior notice to
          you. Specifically, in case of prolonged inactivity, PIOUS CLASSES reserves the right to disable, deactivate
          or terminate a user's account. If an account has been disabled or deactivated for inactivity, the user name
          associated with that account may be given to another user without notice to you or such other party. If you
          violate the Terms of Use, PIOUS CLASSES may at its sole discretion, block, restrict, disable, suspend or
          terminate the paid Services offered on the Platform.

        </p>
      </div>


      <div>
        <h2>INTELLECTUAL PROPERTY
        </h2>

        <p>The Services, including but not limited to digital content on the website i.e. text, graphics, user
          interface, images, video interface and software as well as their selection and arrangement, may belong to
          PIOUS CLASSES or to its partners who have granted PIOUS CLASSES the right to use their content and are
          protected to the fullest extent possible by applicable laws related to copyrights, trademarks, trade secrets
          and all other intellectual property and proprietary rights (collectively, "Intellectual Property Rights" ).
          Any unauthorized use of the Services may violate such laws and the Terms of Use. PIOUS CLASSES reserves all
          its legal rights to prohibit, stop or contain any such violations.

        </p>
        <p>You agree not to copy, republish, frame, download, transmit, modify, adapt, create derivative works based on,
          rent, lease, loan, sell, assign, distribute, display, perform, license, sublicense or reverse engineer the
          Platform and Services or any parts thereof or the selection and arrangement of the Platform and Services,
          except as expressly authorized herein. PIOUS CLASSES grants you a non-exclusive, non-transferable, limited
          permission to access and display the web pages within this Platform, solely on your computer or any other
          electronic viewing device for your personal, non-commercial use of this Platform. This permission is
          conditional on the basis that you shall not modify, alter or illegally use the content displayed on this
          Platform and shall keep intact and comply with all copyright, trademark, and other proprietary notices of the
          Platform, if any. The rights granted to you constitute a license and not a transfer of title in any manner.

        </p>
        <p>Use of the Platform or Services for any other purpose other than expressly granted hereunder is expressly
          prohibited and may result in severe civil and criminal penalties.
        </p>
        <p>In relation to any content submitted, uploaded or generated by you on the Platform, you hereby grant an
          unconditional, unlimited, perpetual, royalty-free, worldwide license and permission to PIOUS CLASSES and
          its affiliates, content sharing partners to display, store, host, publish, digitally reproduce or copy,
          transmit, communicate to the public, modify, translate, distribute or otherwise make such content available on
          the Platform or in relation to the Services provided by PIOUS CLASSES. You agree that such content may
          continue to be made available on PIOUS CLASSES despite any termination or suspension of your account, if
          such content has been shared, stored or embedded by any other user(s). You agree not to hold PIOUS CLASSES
          responsible for the aforesaid activities in relation to your content.

        </p>
      </div>


      <div>
        <h2>ADVERTISEMENTS AND PROMOTIONS
        </h2>

        <p>You acknowledge and agree that PIOUS CLASSES is not responsible or liable for any loss or damage of any
          kind incurred as a result of any such dealings or as the result of the presence of such non-PIOUS CLASSES
          advertisers on the Platform.

        </p>
        <p>Our links with advertisers should not be construed as an endorsement, approval or recommendation by us of the
          owners or operators of those linked websites, or of any information, graphics, materials, products or services
          referred to or contained on those linked websites, unless and to the extent stipulated to the contrary.

        </p>
        <p>The student's image, videos, testimonials, score cards etc can be used for marketing and promotional purposes
          like, but not limited to Digital ads, website, print brochure, pamphlets, print ads etc. We do not need a
          separate consent for this. PIOUS CLASSES is not liable to compensate in any form to the user in any form
          against such promotional, marketing and commercial materials. PIOUS CLASSES holds the exclusive rights to
          change this policy without any notice.

        </p>
      </div>


      <div>
        <h2>SPAM E-MAIL AND POSTINGS
        </h2>

        <p>In the event your access or use of the Platform and Services is found to be spammy, misleading, malicious,
          annoying or containing/promoting unsolicited e-mails or network postings, PIOUS CLASSES reserves its right
          to obtain immediate injunctive relief against you or against such use by you, in addition to all other
          remedies available at law or in equity. PIOUS CLASSES may also opt to block, filter or delete unsolicited
          emails, messages or postings as per its sole discretion.

        </p>
      </div>

      <div>
        <h2>INDEMNITY
        </h2>

        <p>You agree to indemnify, defend and hold PIOUS CLASSES and its officers, directors, partners, employees,
          agents and representatives harmless from and against any and all claims, damages, losses, liabilities, costs
          (including reasonable legal fees) or other expenses that arise directly or indirectly out of or from

        </p>
        <ol>
          <li>Your user content and any other content (e.g. computer viruses) that you may submit, post to or transmit
            through the Platform (including a third party's use of such user content (e.g. reliance on the accuracy,
            completeness or usefulness of your user content).
          </li>
          <li>Your access to or use of the Platform and Services (including any use by your employees, contractors or
            agents and all uses of your account numbers, usernames and passwords, whether or not actually or expressly
            authorised by you, in connection with the Platform and Services).
          </li>
          <li>Your breach of any clause of the ‘Terms of Use’.
          </li>
          <li>Any allegation that while using any of the software made available on the Platform you infringe or
            otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of
            any third party and/or any dealings between you and any third parties advertising or promoting or linked via
            the Platform.
          </li>
          <li>Your activities in connection with the Platform.
          </li>
        </ol>
      </div>

      <div>
        <h2>DISCLAIMER
        </h2>

        <p>We do not represent or guarantee that this Platform will be free from errors or viruses. You acknowledge that
          this Website may be affected by outages, faults or delays. Such outages, faults or delays may be caused by
          factors, including technical difficulties with the performance or operation of our or another person’s
          software, equipment or systems, traffic or technical problems with the Internet or infrastructure failures.

        </p>
        <p>We do not accept responsibility for any loss or damage, however caused (including through negligence), that
          you may directly or indirectly suffer in connection with your use of this Platform, nor do we accept any
          responsibility for any such loss arising out of your use of or reliance on information contained on or
          accessed through this Platform.

        </p>
      </div>


      <div>
        <h2>PRIVACY POLICY
        </h2>

        <p>Your privacy is important to us. Users of our Platform should refer to our <a
          href='privacy-policy'>Privacy
          Policy</a> ,
          which
          is
          incorporated into this Terms of Use by reference, with respect to the disclosure, collection, storage, usage
          and protection of your information during the course of your interaction with the Platform.

        </p>

      </div>


      <div>
        <h2>COPYRIGHT POLICY
        </h2>

        <p>PIOUS CLASSES respects and recognize the intellectual property rights of others and expects users of
          PIOUS CLASSES to do the same. This document may be read as part and parcel of the Terms of Use. Your use
          and/or continued use of the PIOUS CLASSES and/or mobile app shall be taken to mean an express
          consent and acceptance to the terms of this Copyright Policy as well as the Terms of Use.

        </p>
        <p>If you believe that your content has been used on PIOUS CLASSES in a way that constitutes an infringement
          of your copyright, please notify PIOUS CLASSES’s designated agent for complaints (contact email below) or
          approach us through the procedure set out under the applicable provisions of the Information Technology Act.
          If you have a good faith belief that your copyright has been infringed by any of our Users, you may follow the
          below process:

        </p>
        <p>You could send a notice to us or our designated agent by email to piousclasses16@gmail.com.com</p>
      </div>


      <div>
        <h2>AMENDMENTS TO THE TERMS AND CONDITIONS
        </h2>

        <p>PIOUS CLASSES may amend the Terms of Use and other PIOUS CLASSES Policies published on the Platform
          from time to time at its sole discretion. Your continued access or use of the Platform and the Services
          constitutes your acceptance of the amendments. Your access and use of the Platform and Services will be
          subject to the most current version of the Terms of Use, Privacy Policy and Copyright Policy posted on the
          Platform at the time of such use. Please regularly check the Platform to view the latest version of PIOUS
          CLASSES policies.

        </p>
      </div>


      <div>
        <h2>APPLICABLE LAW AND JURISDICTION
        </h2>

        <p>Terms of Use shall be governed by and construed in accordance with the Laws of India. In case of any dispute,
          the courts in Delhi will have exclusive jurisdiction to try any such disputes to the exclusion of all other
          courts.

        </p>
      </div>


    </div>
  );
};

export default TermsAndCondition;
