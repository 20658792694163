import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import CustomeForm from 'components/Form/form';

const MyVerticallyCenteredModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Fill this form to process your payment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomeForm batch={props.batch} heading={props.heading} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default MyVerticallyCenteredModal;
