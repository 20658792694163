import React, {useState} from 'react';
import "./formInput.css";

const FormInput = (props) => {
  const [leave, setLeave] = useState(false);

  const {label, onChange, errorMessage, id, ...inputProps} = props;

  const handleLeave = (e) => {
    setLeave(true);
  }
  return (
    <div className="formInput">
      <label className="demoLabel">{label}</label>
      <input className="demoInput" {...inputProps} onChange={onChange} onBlur={handleLeave} leave={leave.toString()}/>
      <span className="demoSpan">{errorMessage}</span>
    </div>
  );
};

export default FormInput;
