import { FaBars } from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import { Nav, NavbarContainer, NavLogo, MobileIcon} from '../Navbar/cssNavbar'
import { animateScroll as scroll } from 'react-scroll';

const NavbarBatches = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={true}>
          <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome}>Pious Classes</NavLogo>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default NavbarBatches
