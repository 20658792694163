import React from 'react'
import { Heading, Container, FormWrap, Icon, FormContent, Form, FormInput, FormH1, FormLabel, FormButton, Text } from './cssSignin'
import {toast} from "react-toastify";
import ReactGa from 'react-ga';
import { useEffect, useState} from 'react';

const SignIn = () => {

  const [email, setEmail] = useState('');


  const handleOnSubmit = (event) => {
    event.preventDefault();
    ReactGa.event({
      category: 'SignIn',
      action: email.match(/^([^@]*)@/)[1],
    })
    toast.error('Incorrect mail or password. Please try again.', {position: toast.POSITION.TOP_CENTER, autoClose: 2000});
  }


  const handleChange = (event) => {
    setEmail(event.target.value);
  }

  return (
    <>
      <Container>
        <FormWrap onSubmit={handleOnSubmit}>
          <Icon><Heading to='/'> Pious Classes </Heading></Icon>
          <FormContent>
            <Form action='#'>
              <FormH1>Sign in to your account</FormH1>

              <FormLabel htmlFor='mail'>Email</FormLabel>
                <FormInput id = 'mail' type="email" value = {email} onChange={handleChange} required />

              <FormLabel htmlFor='pass'>Password</FormLabel>
                <FormInput id = 'pass' type = "password" required />

              <FormButton type='submit'>Log In</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default SignIn
