import React, {useState} from 'react';
import axios from "axios";
import {toast} from "react-toastify";

toast.configure();

function loadScript(src) {


  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default async function(props) {

  props.setIsLoading(true);
  const res = await loadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
    toast.error("Razorpay SDK failed to load. Are you online?", {position: toast.POSITION.TOP_CENTER, autoClose: 8000});
    props.setIsLoading(false);
    return;
  }
  // creating a new order
  const result = await axios.post(process.env.REACT_APP_APP_BASE_URL + `/payment/orders/${props.id}`);


  if (!result) {
    toast.error("Server error. Are you online?", {position: toast.POSITION.TOP_CENTER, autoClose: 8000});
    props.setIsLoading(false);
    return;
  }

  // Getting the order details back
  const { amount, id: order_id, currency } = result.data;


  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: "Pious Classes",
    description: props.topLine,
    order_id: order_id,
    handler: async function (response) {
      const data = {
        orderCreationId: order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };

      await axios.post(process.env.REACT_APP_APP_BASE_URL + "/payment/success", data).then((result) => {

        if(result.data.msg === 'Success') {
          toast.success('We will send you the invoice and class details within 24 hours on your mail and phone number. Thank you for choosing pious classes.', {position: toast.POSITION.TOP_CENTER, autoClose: 20000});
          props.setIsLoading(false);
        } else {
          toast.error('Please retry. Payment didn\'t succeeded!!', {position: toast.POSITION.TOP_CENTER, autoClose: 60000});
          props.setIsLoading(false);
        }
      }).catch((error) => {
        toast.error("Server is down! Please retry after sometime", {position: toast.POSITION.TOP_CENTER, autoClose: 60000});
        props.setIsLoading(false);
      });
    },
    theme: {
      color: "#61dafb",
    },
    image: '/images/piousLogo.jpg',
    prefill: {
      name: props.name,
      email: props.email,
      contact: props.phone,
    },
    notes: {
      Name: props.name,
      Batch: props.days == 3?props.batch:null,
      Time: props.time,
      Timing: props.time1,
      Timing1: props.time2,
      Timing2: props.time3,
      Days: props.days,
      Months: props.months,
    },
    modal: {
      "ondismiss": function(){
        props.setIsLoading(false);
      }
  }
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}