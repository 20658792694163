import React, {useState} from 'react'
import ScrollToTop from '../ScrollToTop'
import {advanceObj} from './BatchesData';
import Batches from "./batches";
import Whatsappfooter from "../Footer/WhatsappFoot";
import Footer from "../Footer/footer";
import NavbarBatches from "./navbarBatches";
import ReactGa from 'react-ga';
import { useEffect } from 'react';



const AdvancedBatch = () => {

  return (
    <>
      <ScrollToTop />
      <NavbarBatches/>
      <Batches {...advanceObj}/>
      <Whatsappfooter />
      <Footer/>
    </>
  )
}

export default AdvancedBatch