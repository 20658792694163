import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const PrivacyPolicy = () => {
  const {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname]);

  return (
    <div>

      <h1>PRIVACY POLICY</h1>
      <p>The following privacy policy is being published in accordance with the provisions of the Information Technology
        Act, 2000 and other applicable Rules thereunder, including but not limited to the Information Technology
        (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 ("IT Rules").</p>
      <p>This Privacy Policy explains the policy of PIOUS CLASSES website - www.piousclasses.com,
        (hereinafter referred to as "the Website") with respect to the disclosure, collection, storage, usage and
        protection of your
        information during the course of your interaction with the Website and the App.</p>
      <p>Please read this Privacy Policy carefully and in conjunction with the Terms of Use. If you do not understand
        this policy, or do not accept any part of it, then you should not use the Platform, as the case may be. Your use
        and/or continued use of the Platform, as the case may be amounts to express consent to the terms of this Privacy
        Policy as well as the Terms of Use.</p>
      <p>For the purposes of this Policy, accessing of the Website together with any study material made
        available or uploaded therein or downloaded, embedded therefrom shall collectively be referred to as the
        "Services". The Website shall be collectively referred to as "the Platform".</p>
      <p>This Privacy Policy forms part and parcel of the Terms of Use for the PIOUS CLASSES Services and
        shall be
        read as a whole. Capitalized terms used here, but undefined, shall have the same meaning as attributed to them
        in the Terms of Use.</p>

      <h2>INFORMATION COLLECTED FROM YOU</h2>
      <ol>
        <li>When you use the Platform by way of registration, log in, creation of a user account or creation of a post,
          purchase of a online course, attempting a quiz, etc. or by way of interaction via third party websites
          and/or mobile applications or by way of any other communication with the Platform, PIOUS CLASSES may
          collect your personally identifiable information including name, date of birth, gender, demographic
          information, photos, e-mail address, telephone number, mobile phone number, credit card or debit card details,
          geographic location, mailing address, social media account details including list of contacts/friends.
        </li>
        <li>PIOUS CLASSES will use this information to validate you as a user when using the Platform, to
          provide the Service to you, including administration of your user account, to notify you of changes to the
          Service or about any changes to our terms and conditions or this privacy policy, to manage its business,
          including financial reporting and billing of its Service, for the development of new products and services, to
          send you newsletters, offers and promotion coupons to market and advertise its products and services by email,
          to comply with applicable laws, court orders and government enforcement agency requests, for research and
          analytic purposes including to improve the quality of the Service and to ensure that the Service is presented
          in the most effective manner for you and your device.
        </li>
        <li>By registering on the Platform, you agree and confirm your consent to providing PIOUS CLASSES
          with your
          aforementioned personal information, which is lawful, necessary and permissible. You at all times have the
          right to discontinue the use of the Platform. PIOUS CLASSES'S Exam Prep is not liable to provide you with its
          Services
          in the absence of or your refusal to provide the aforesaid information. Additionally, PIOUS CLASSES is not
          liable to ensure or maintain the same quality of its Services to you, as it may for a user who provides all
          aforementioned information.
        </li>
        <li>Any information provided by you under PIOUS CLASSES "create a feedback" feature, comments
          or otherwise any information that you provide on the Platform is not personal information and shall become
          part
          of PIOUS CLASSES published content. PIOUS CLASSES may use the aforementioned
          information without your
          consent for purposes including but not limited to improving its products and services, developing new products
          and services and sending you messages about promotions and offers.
        </li>
      </ol>

      <h2>INFORMATION COLLECTED AUTOMATICALLY</h2>
      <ol>
        <li>
          When you visit or interact with the Platform, apart from PIOUS CLASSES certain third party
          advertisers
          and/or service providers may use technologies that automatically collect information about you for both
          transactional (e.g., confirmation of registration, notification of purchase made, etc.) and promotional (e.g.,
          promotions, newsletters, etc.) purposes. Your information may be collected by PIOUS CLASSES or
          such third
          party advertisers and/or service providers in the following ways:-
          <ol>
            <li>Log Files: Every time you visit the Platform, PIOUS CLASSES servers automatically receive
              and log
              information from your browser and device used to access the Platform (such as IP address, device ID,
              details of your network operator and type, your operating system, browser type and version, CPU speed, and
              connection speed). This enables us to validate you as a User, to understand your usage of the Platform and
              helps us to make changes and updates most suited to your needs and interests.
            </li>
            <li>Cookies: Cookies are data files placed on your device, used to keep track of information such as your
              interaction with social media websites, the content you click on, download, upload or share and other
              activity on the Platform etc. in order to improve your experience of the Platform by personalizing it to
              your preferences and usage trends.
            </li>
            <li>Mobile Analytics: Google analytics software is used by PIOUS CLASSES to better understand
              and
              customize the functionality of the website’s. This is done by collecting
              information
              such as your frequency of the website’s usage, the features you prefer to use on the Website, etc.
            </li>
            <li>Payment /Purchase Information: In order to access certain paid features and services on the Platform,
              you may be required to create or log into a separate account on a payment gateway or website such as
              “Razorpay”. Once such an account is created, in order to process your payments/ purchases on the
              Platform, such payment gateway provider may require and collect your details such as name, address, phone
              number, email address and credit or debit card information, net banking information or details of any web
              wallets maintained by you. Any/all payment(s) made/processed or details provided to or shared with such
              authorized payment gateway providers shall be stored directly by such payment gateway providers without
              any information passing through or relayed to PIOUS CLASSES. PIOUS CLASSES assumes
              no liability in
              respect of such payments and/or information shared with or provided to such authorized payment gateway
              providers. It is further clarified that the aforementioned information is only used in accordance with the
              provisions of the applicable law and in strict adherence to this Privacy Policy.
            </li>
            <li>Public Forums: Any information that is disclosed by you in the comments section or by way of the “create
              a feedback” feature, becomes published information and PIOUS CLASSES shall not be held liable for
              the
              security of the same or any personal information that you disclose herein. You agree to exercise caution
              when disclosing any personal information or personally identifiable information in this regard.
            </li>
          </ol>
        </li>
      </ol>
      <li>Please note that we only use the aforesaid information to communicate with and/or improve the Service and to
        better understand our users' operating systems, for system administration and to audit the use of the Service.
        We do not use any of the aforesaid data to identify the name, address or other personal details of any
        individual.
      </li>
      <li>For the purpose of this Privacy Policy, any passwords, financial information such as credit or debit card
        details or other payment instrument details and any additional information prescribed by law to be sensitive
        that may be collected by PIOUS CLASSES during your use of the Platform and the Services provided
        thereon,
        shall be referred to as "Sensitive Personal Data or Information".
      </li>

      <h2>
        LINK TO THIRD PARTIES
      </h2>
      <ol>
        <li>
          The Platform may include links that redirect you to other websites. This Privacy Policy does not cover these
          third party websites. You agree that once you leave our servers, any third party websites that you go to or
          interact with are at your own risk. PIOUS CLASSES shall not be held liable for any default, loss
          of
          function
          or any risk that your sensitive personal information may be exposed to as a result of the same.
        </li>
      </ol>


      <h2>
        SHARING OR DISCLOSURE OF INFORMATION COLLECTED
      </h2>
      <ol>
        <li>
          As a strict policy, we will not disclose, share or exploit your information with anyone without your express
          permission.
        </li>
        <li>
          However, we may be mandated under law or under contracts to make certain limited disclosures under the
          following circumstances:
          <ol>
            <li>
              Legal Necessity: PIOUS CLASSES may share any of the aforesaid information, including your
              personally
              identifiable information or Sensitive Personal Data or Information, without obtaining a separate consent
              from you, if and when such information is requested or required by law or by any court or governmental
              agency or authority to disclose, for the purpose of verification of identity, or for the prevention,
              detection, investigation of any criminal activity, or for prosecution and punishment of offences.
            </li>
            <li>
              Limited Disclosure to Service Providers: we may disclose your information (but not Sensitive Personal Data
              or Information) to our service providers and business partners ("Service Providers") for the purposes of
              betterment and improvement of our services including but not limited to hosting the Platform, payment
              processing, analyzing data, providing customer service, etc, for the purpose of making various features,
              services and products of PIOUS CLASSES available to you and investigating or redressing
              grievances.
              This will be in the form of aggregated anonymized data and will be under strict contractual arrangements
              that preserve the confidentiality and security of your personal information in accordance with this
              Privacy Policy;
            </li>
            <li>
              Limited Disclosures for improvement of services: PIOUS CLASSES may share the aforesaid
              information
              including your personally identifiable information (but not Sensitive Personal Data or Information) when
              it is required to be shared with sponsors, partners, advertisers, analytics companies or third parties for
              the purpose of marketing, advertising promotional offers, offering product information and market
              research, in connection with the Service. This will be in the form of aggregated anonymized data and will
              be under strict contractual arrangements that preserve the confidentiality and security of your personal
              information in accordance with this Privacy Policy;
            </li>
          </ol>
        </li>
      </ol>
      <h2>
        SECURITY OF INFORMATION COLLECTED
      </h2>
      <ol>
        <li>
          We take the security of your personal information seriously and use appropriate technical and organizational
          measures to protect your personal information against unauthorized or unlawful processing and against
          accidental loss, destruction or damage. Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your personal data, we cannot guarantee the
          security of your data which is transmitted to or from the Service. Any transmission is at your own risk.
        </li>
        <li>
          We keep your personal information for no longer than is necessary for our business purposes or for legal
          requirements.
        </li>
      </ol>
      <h2>
        CONTACT US
      </h2>
      <p>
        If you have any questions or concerns regarding the Privacy Policy, kindly contact us at
        piousclasses16@gmail.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
