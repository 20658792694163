import React from 'react'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import {
  CoursesContainer,
  CoursesH1,
  CoursesWrapper,
  CoursesCard,
  CoursesIcon,
  CoursesH2,
  CoursesP,
  ArrowForward, ArrowRight, HeroBtnWrapper
} from './cssCourses'
import {Button} from "./cssCourses";
import {Underline} from "../AboutUs/cssAboutus";

const Courses = () => {

  const [hover, setHover] = React.useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <CoursesContainer id='Course'>
      <CoursesH1>Our Course</CoursesH1>
      <Underline/>

      <CoursesWrapper>
        <CoursesCard>
          <CoursesIcon src={Icon2}/>
          <CoursesH2>Complete English Proficiency</CoursesH2>
          <CoursesP>We focus primarily on interactive activities to build your fluency, confidence, soft-skills along
            with vocabulary and pronunciation.</CoursesP>
          <HeroBtnWrapper>
            <Button to='/advance' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'
                    duration={500} exact='true' offset={-80}>
              Enroll {hover ? <ArrowForward/> : <ArrowRight/>}
            </Button>
          </HeroBtnWrapper>
        </CoursesCard>
      </CoursesWrapper>
    </CoursesContainer>
  )
}

export default Courses
