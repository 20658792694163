export const questionAnswer = [
  {
    id: 1,
    question: "What is the duration of one class?",
    answer: "Each session is of 1 hour."
  },
  {
    id: 2,
    question: "What if I miss a particular session?",
    answer: "We record all our live classes. And thus you would be provided with the recording of every session of your batch."
  },
  {
    id: 3,
    question: "How many students would be there in the class?",
    answer: "There would never be more than 10 students in a group class. The ratio would be 1:10"
  },
  {
    id: 4,
    question: "What is the duration of the course ?",
    answer: "The duration of the full course is 3 months, after which we ensure that you will become a better" +
      " speaker. We also provide the completion certificate. You can even extend your course if you don't feel" +
      " confident enough without any extra cost."
  },
  {
    id: 5,
    question: "I don't even know the basics of English, Will this course help me?",
    answer: "Actually, our course pre-requisite is that you should have some basic idea of the fundamentals of " +
      " English as we" +
      " will mostly focus on interactive activities, vocabulary and grammar rules. So enroll only if you know some" +
      " basic" +
      " English. You can first enroll for the demo to get the actual feel of the class if you are in doubt, which is" +
      " free of cost."
  },
  {
    id: 6,
    question: "I know basic english, it's just that I'm not fluent and feel scared of getting judged? Will this" +
      " course help me?",
    answer: "Yes, definitely the complete proficiency course would be the best fit for you. All the students of" +
      " this course would" +
      " comprise of students who understand the basic fundamentals of english but lack fluency, confidence," +
      " vocabulary and grammar. "
  },
  {
    id: 7,
    question: "Would the session be live or recorded?",
    answer: "Classes will be taken up by Jaanvi ma'am and would be live. Recording of each class would be shared on" +
      " the" +
      " group so that students can go through it later."
  },
  {
    id: 8,
    question: "Will I get a certificate after finishing the course?",
    answer: "Yes, you will get a certificate after finishing the course."
  },
  {
    id: 9,
    question: "What are the timings of the classes?",
    answer: "When you fill the form for the course, you will be given the option to choose the timings of your batch."
  },
  {
    id: 10,
    question: "Will I get the study material?",
    answer: "Yes, soft copy of study material is provided to the students via PDF and PPTs."
  },
  {
    id: 11,
    question: "Can I change my batch timing after enrollment?",
    answer: "Yes, after enrollment you can change your batch timings if there is any availability in other batches.."
  },
  {
    id: 12,
    question: "Do you provide refund?",
    answer: "We provide refund only on valid reasons."
  },
]