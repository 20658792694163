import React from 'react';

import {Button, Modal, Alert, Table, Row, Col} from 'react-bootstrap';

const MyFeesPlanModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Every live session will be of 1 hour.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th>Duration</th>
            <th>Mon-Thu (4 days/week)</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>3 Months<br/>(You can keep availing sessions, till the time you feel
              satisfied
              with your
              fluency skills.)
            </th>
            <td>{props.batch === 'one-one' ? 'Rs. 8499/-' : 'Rs. 9999/-'}(Certification)</td>
          </tr>
          </tbody>
        </Table>
        <Alert style={{fontSize: '12px'}}>
          <div dangerouslySetInnerHTML={{__html: props.note}}/>
        </Alert>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default MyFeesPlanModal;
