import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import moment from 'moment';

import {toast} from "react-toastify";

toast.configure();

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default function ScrollToTop() {
  const {pathname} = useLocation()

  useEffect(() => {
    const batch = pathname.substring(1);
    let capitalBatch = batch.charAt(0).toUpperCase() + batch.slice(1);
    let days = 0;
    let today = new Date();
    console.log(today);
    let dd = String(today.getDate()).padStart(2, '0');
    let remainingMin = moment().endOf('day').fromNow();
    if (batch === 'oneone') {
      days = dd % 3 + 2;
    } else if (batch === 'advance') {
      days = (dd + 12) % 2 + 1;
    } else {
      days = dd % 6 + 2;
    }
    toast.info(`Yesterday ${days} students enrolled for it! (Next Update ${remainingMin})`,
      {position: toast.POSITION.BOTTOM_CENTER, autoClose: 8000});
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}