export const beginnerObj = {
  id: 'beginner',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Beginner Batch',
  headline: 'Features',
  description1: '<b style="color:black;font-size:13px;">Strengthening your grammar: </b> Learn the basics of grammar with us and overcome you grammatical fear.',
  description2: '<b style="color:black;font-size:13px;">Building vocabulary : </b> If you feel you miss out on words while speaking, build your vocabulary with us while also learning the vocabulary hacks.',
  description3: '<b style="color:black;font-size:13px;">Special Pronunciation sessions: </b> Learn the pronunciation of the most mispronounced words and be one step closer of becoming a better speaker.',
  description4: '<b style="color:black;font-size:13px;">Practical English: </b> English speaking is an art. It includes presentation, stage use, story-telling, gestures, pauses, intonation, voice modulation and visual expressions. Learn to master them.',
  description5: '<b style="color:black;font-size:13px;">Sentence framing: </b> Educate yourself about efficient sentence formation and learn about the most common errors that learners make.',
  description6: '<b style="color:black;font-size:13px;">Fun learning activities: </b> Learn about Visual aid, Workbook sessions, Group discussions, Humorous Speeches, Telephonic Communication, Situational conversations and Soft skills.',
  buttonLabel: 'Enroll',
  availBatches: '7-8 P.M',
  notAvailBatches: '3-4 P.M, 4-5 P.M, 5-6 P.M',
  imgStart: true,
  img: '/images/svg-4.svg',
  alt: 'English Image',
  dark: false,
  primary: false,
  darkText: true,
  note: '<b style="color:black;font-size:13px;">Note: </b> Full course is of 3 months and there are two options. If you want more rigorous training, opt for 6 days a week. Else if you have time constraints you can go for 3 days/week. Either ways we will make sure that you become much more fluent and confident on the completion of the course. If you don\'t want to opt for the full course, you can pay on a monthly basis for as long as you want and see the result for yourself. <b style="color:black;font-size:12px;">You can get yourself a demo/trial too! To get the recording of the demo lecture, drop us a text on whatsapp.</b> ',

};

export const advanceObj = {
    id: 'advance',
    lightBg: true,
    lightText: false,
    lighttextDesc: false,
    topLine: 'Complete English Proficiency',
    headline: 'Features',
    description1: '<b style="color:black;font-size:13px;">English Essentials: </b> Polishing Grammar, Vocabulary ranging from casual to business/formal, Idioms and Phrases, Slangs and Proverbs.',
    description2: '<b style="color:black;font-size:13px;">5 W\'s & 1 H: </b> Learn Who, What, Where, When, Why & How of every talk or speech.',
    description3: '<b style="color:black;font-size:13px;">Oratory Skills: </b> Speeches, Debates, Group Discussions, Presentation Rounds, Impromptu Speeches, Humorous Speeches, Prop Speeches, Special Occasion Speeches, Role Plays, Picture Description, Anchoring, Telephonic Communication.',
    description4: '<b style="color:black;font-size:13px;">Personality Development: </b>Gain confidence & learn the art of body language.',
    description5: '<b style="color:black;font-size:13px;">Pronunciation & Voice Modulation: </b>Because it’s NOT just about WHAT you say, it’s about HOW you say too. Learn the art to influence and convince people.',
    description6: '<b style="color:black;font-size:13px;">Learn to NAIL the Interview: </b>Do’s and Don’ts of Interviews, making and receiving interview calls, resume writing, Mock Sessions.',
    buttonLabel: 'Enroll',
    imgStart: true,
    availBatches: '8-9 P.M',
    notAvailBatches: '3-4 P.M, 4-5 P.M, 5-6 P.M',
    img: '/images/svg-4.svg',
    alt: 'English Image',
    dark: false,
    primary: false,
    darkText: true,
    note: '<b style="color:black;font-size:13px;">Note: </b>We will make sure that you become much more fluent and' +
      ' confident on the completion of the course. <b style="color:black;font-size:12px;">If you don\'t feel' +
      ' confident/satisfied after 3 months, you can continue with the course' +
      ' untill you reach your desired level of fluency.</b>'
  }
;

export const oneoneObj = {
  id: 'one-one',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Individual Sessions',
  headline: 'Features',
  description1: '<b style="color:black;font-size:13px;">Get yourself an english buddy: </b> This class is curated' +
    ' only for you and it just involves you and the Jaanvi ma\'am, hence you get all the attention leading to' +
    ' efficient' +
    ' learning experience.',
  description2: '<b style="color:black;font-size:13px;">Create your own curriculum: </b> The training will be given to you as per you strengths and weaknesses. So we help you modify your course that will speed track your learning experience.',
  description3: '<b style="color:black;font-size:13px;">Flexible timings and days: </b> Considering your hectic schedule, we give you the authority to choose you own timings and days making the experience even smoother.',
  description4: '<b style="color:black;font-size:13px;">Personality Development: </b>Gain confidence, learn the art of body language and etiquettes in different situations and polish your body image.',
  description5: '<b style="color:black;font-size:13px;">Unique feature: </b>We also cover essential topics like interview preparations, mock interviews, how to influence people, email writing and business etiquette.',
  buttonLabel: 'Enroll',
  imgStart: true,
  availBatches: '7-8 P.M',
  notAvailBatches: '3-4 P.M, 4-5 P.M, 5-6 P.M',
  img: '/images/svg-4.svg',
  alt: 'English Image',
  dark: false,
  primary: false,
  darkText: true,
  note: '<b style="color:black;font-size:13px;">Note: </b> The days and timings are flexible for the individual sessions. Our team would contact you shortly after the payment is made to discuss about the details further.',
};