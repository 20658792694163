import React from 'react'
import {FaInstagram, FaLinkedin, FaYoutube} from 'react-icons/fa'
import {
  FooterContainer,
  FooterWrap,
  LegalLogo,
  LegalPage,
  LegalWrap,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap
} from './cssFooter'
import {animateScroll as scroll} from 'react-scroll';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              Pious Classes
            </SocialLogo>
            <SocialIcons>
              <SocialIconLink href='//www.instagram.com/pious_classes/' target='_blank' arial-label='Instagram'>
                <FaInstagram/>
              </SocialIconLink>
              <SocialIconLink href='//www.youtube.com/channel/UCxKQZldIqRSJFd828hKVpKQ' target='_blank'
                              arial-label='Youtube'>
                <FaYoutube/>
              </SocialIconLink>
              <SocialIconLink href='//www.linkedin.com/in/jaanvi-taneja/' target='_blank' arial-label='Linkedin'>
                <FaLinkedin/>
              </SocialIconLink>
            </SocialIcons>


          </SocialMediaWrap>

          <LegalWrap>

            <LegalPage>
              <LegalLogo to='/privacy-policy'>
                Privacy Policy
              </LegalLogo>
              <LegalLogo to='/terms-and-condition'>
                Terms and Conditions
              </LegalLogo>
              <LegalLogo to='/refund-and-cancellation-policy'>
                Refund and cancellation policy
              </LegalLogo>
            </LegalPage>

          </LegalWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
