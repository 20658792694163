import React, {Component} from 'react';
import {
  Column1,
  Column2, Heading,
  Img, ImgWrap, InfoContainer,
  InfoRow,
  InfoWrapper,
  TextWrapper, TitleHeading,
} from "./cssContactus";
import Forms from "../Forms";
import {Alert} from "react-bootstrap";
import {Underline} from "../AboutUs/cssAboutus";

class ContactUs extends Component {
  render() {
    return (
      <>
        <InfoContainer lightBg={true} id='contactus'>
          <TitleHeading> Contact Us </TitleHeading>
          <Underline/>
          <InfoWrapper>
            <InfoRow imgStart={false}>
              <Column1>
                <TextWrapper>
                  <Alert  style={{marginBottom: '30px'}}>
                    Email: piousclasses16@gmail.com
                  </Alert>
                  <Alert  style={{marginBottom: '40px'}}>
                    Contact Number: +91 9560990481
                  </Alert>
                  <Forms/>
                </TextWrapper>
              </Column1>
              <Column2>
                <ImgWrap>
                  <Img src={'/images/contact-us.png'} alt='car'/>
                </ImgWrap>
              </Column2>
            </InfoRow>
          </InfoWrapper>
        </InfoContainer>
      </>
    );
  }
}

export default ContactUs;