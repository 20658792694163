import React from 'react'
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Points,
  PointsHeading,
  ImgWrap,
  Img,
  UnderlineAboutUs,
  AboutUsH1,
} from './cssAboutus'


const AboutUs = () => {

  return (
    <>
      <InfoContainer lightBg={true} id='aboutus'>
        <AboutUsH1>About Us</AboutUsH1>
        <UnderlineAboutUs></UnderlineAboutUs>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                <TopLine>Conquer your fear of public speaking. </TopLine>
                <Heading lightText={false}>Why should you enroll in our class?</Heading>
                <Points darkText={true}> <PointsHeading>1. Learn efficiently:</PointsHeading> New batches are created
                  every week so that every student gets a fresh batch. We make sure that the number doesn't exceed 10
                  students in a batch. </Points>
                <Points darkText={true}> <PointsHeading>2. Boosts confidence:</PointsHeading> Helps you to overcome the
                  fear of judgement and hence become a confident English speaker. </Points>
                <Points darkText={true}> <PointsHeading>3. Personal Satisfaction:</PointsHeading> Accomplish your dream
                  of presenting yourself in front of the crowd. </Points>
                <Points darkText={true}> <PointsHeading>4. Learn to Argue:</PointsHeading> Being able to keep your
                  opinion in a polite yet smart way is a much needed skill nowadays. </Points>
                <Points darkText={true}> <PointsHeading>5. Career advancement:</PointsHeading> When you are a good
                  public speaker, you will be more comfortable in interviews and would even be able to convince your
                  boss for a promotion. </Points>
                <Points darkText={true}> <PointsHeading>6. Stand out in the workplace:</PointsHeading> Most will avoid
                  speaking up and putting themselves in the spotlight at work, but with public speaking skills you have
                  the confidence to do so! </Points>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src='/images/svg-1.svg' alt='car'/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default AboutUs
