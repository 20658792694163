import * as React from 'react';

import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import {questionAnswer} from "./dataFaq";


import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { AccordionActions, Button, Divider } from '@material-ui/core';
import {useState} from "react";
import {Underline} from "../AboutUs/cssAboutus";

export const FaqWrapper = styled.div`
  height: 1100px;
  background: #bee1e6;

  @media screen and (max-width: 786px) {
    height: 1200px;
  }

  @media screen and (max-width: 480px) {
    height: 1200px;
  }

  @media screen and (max-width: 448px) {
    height: 1400px;
  }

  @media screen and (max-width: 348px) {
    height: 1800px;
  }
`

export const FaqContainer = styled.div`
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 968px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`
export const FaqH1 = styled.h1`
  font-size: 2.5rem;
  color: black;
  margin-top: 64px;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`
const Accordion = withStyles({
  root: {
    width: '80%',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: '0px',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid #12738E',
    marginBottom: -1,
    color: '#666666',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function ControlledAccordions() {

  const [data, setData] = React.useState(questionAnswer);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <FaqWrapper id = "faq">
        <FaqContainer>
          <FaqH1>FAQs</FaqH1>
          <Underline />
          {
            data.map((item) => {
              const {id, question, answer} = item;
              return (
                    <Accordion key={id} expanded={expanded === `{"panel"}${id}`} onChange={handleChange(`{"panel"}${id}`)} sx={{width: '100%'}}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        {question}
                      </AccordionSummary>
                      <AccordionDetails>
                        {answer}
                      </AccordionDetails>
                      <Divider />
                      <AccordionActions>
                        <Button size="small" onClick={() => alert('Please write us a feedback/query in the contact us section.')}>Not Helpful</Button>
                        <Button size="small" color="primary" onClick={() => alert('Thanks for your feedback')}>Helpful</Button>
                      </AccordionActions>
                    </Accordion>
                );
            })
          }
        </FaqContainer>
      </FaqWrapper>
    </div>
  );
}