import styled from 'styled-components'
import {Link as LinkR} from "react-router-dom";
import {MdArrowForward, MdKeyboardArrowRight} from "react-icons/md";

export const CoursesContainer = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FCD8D4;

  @media screen and (max-width: 1000px) {
    height: 900px;
  }

  @media screen and (max-width: 786px) {
    height: 1000px;
  }

  @media screen and (max-width: 480px) {
    height: 1000px;
  }
`
export const CoursesWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
`
export const CoursesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 600px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`
export const CoursesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;

`
export const CoursesH1 = styled.h1`
  font-size: 2.5rem;
  color: black;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 50px;
  }
`
export const CoursesH2 = styled.h1`
  font-size: 1.6rem;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
`

export const CoursesP = styled.p`
  font-size: 1rem;
  text-align: center;
`
export const Button = styled(LinkR)`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#01BF71' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px  48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#010606' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: end;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#EB4B0C' : '#01BF71')};
  }
`

export const Buttonbutton = styled.button`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#01BF71' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px  48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#010606' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: end;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#EB4B0C' : '#01BF71')};
  }
`
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`
