import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarRoute, SideBtnWrap } from './cssSidebar'

const Sidebar = ({isOpen,toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='aboutus' onClick={toggle}>About Us</SidebarLink>
          <SidebarLink to='Courses' onClick={toggle}>Courses</SidebarLink>
          <SidebarLink to='reviews' onClick={toggle}>Reviews</SidebarLink>
          <SidebarLink to='faq' onClick={toggle}>FAQ's</SidebarLink>
          <SidebarLink to='contactus' onClick={toggle}>Contact Us</SidebarLink>
          <SideBtnWrap>
            <SidebarRoute to='/signin'>Sign in</SidebarRoute>
          </SideBtnWrap>
        </SidebarMenu>

      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
