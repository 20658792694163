import React, {useState} from 'react';
import FormInput from "./FormInput";
import './formInput.css'
import axios from "axios";
import {toast} from "react-toastify";

toast.configure();


const FreeForm = () => {
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    dob: "",
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Enter your name",
      label: "Full Name:",
      errorMessage: "Name should be 3-16 characters long and shouldn't include any special characters.",
      required: "true",
      pattern: "^[a-zA-Z0-9_ ]*$",
    },
    {
      id: 2,
      name: "mobile",
      type: "tel",
      placeholder: "Enter your mobile number",
      label: "Mobile Number:",
      errorMessage: "Mobile number should be of length 10 and should be numeric.",
      required: "true",
      pattern: "^(\\+91[\\-\\s]?)?[0]?(91)?[789]\\d{9}$",
    },
    {
      id: 3,
      name: "dob",
      type: "date",
      placeholder: "Enter your Birthday",
      label: "Date of Birth:",
      errorMessage: "DOB is not correct. Please add a valid date of birth.",
    },
    {
      id: 4,
      name: "email",
      type: "email",
      placeholder: "Enter your email address",
      label: "Email:",
      errorMessage: "Email is not correct. Please type a valid email.",
      required: "true",
    },
  ]

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("We will contact you soon regarding dates for your free demo!",
      {position: toast.POSITION.TOP_CENTER, autoClose: 8000});
    createEntryInDb();
  }

  const createEntryInDb = async () => {
    const res = await axios.post(process.env.REACT_APP_APP_BASE_URL + `/demoUsers/add/${values.email}`, values);
  }

  const onChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value})
  }

  return (
    <div className="formContainer">
      <form className="demoForm" onSubmit={handleSubmit}>
        <h1 className="demoH1">Register for free demo</h1>
        {
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}/>
          ))
        }
        <button className="demoButton"> Submit</button>
      </form>
    </div>
  );
};

export default FreeForm;
