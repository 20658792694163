import styled from "styled-components";
import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const Button = styled.a`
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 90px;
  text-align: center;
  box-shadow: 3px 4px 3px #999;
  right: 20px;
  z-index: 100;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: gray;
  }

  @media screen and (max-width: 1210px) {
    width: 50px;
    height: 50px;
  }

  @media screen and (max-width: 480px) {
    width: 30px;
    height: 30px;
    right: 15px;
  }
`
const Whatsapp = styled(WhatsAppIcon)`
  transform: scale(4.8);
  position: relative;
  top: 25px;

  @media screen and (max-width: 1210px) {
    transform: scale(3.0);
    top: 10px;
  }

  @media screen and (max-width: 480px) {
    transform: scale(1.8);
    top: 2px;
  }
`

function Whatsappfooter() {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Contact us on whatsapp
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement="left"
        delay={{show: 150, hide: 100}}
        overlay={renderTooltip}
      >
        <Button href='https://wa.link/o0klft'>
          <Whatsapp/>
        </Button>
      </OverlayTrigger>
    </>
  )
}

export default Whatsappfooter