import React from 'react'
import ScrollToTop from '../ScrollToTop'
import {beginnerObj} from './BatchesData';
import Batches from "./batches";
import Whatsappfooter from "../Footer/WhatsappFoot";
import Footer from "../Footer/footer";
import NavbarBatches from "./navbarBatches";
import ReactGa from 'react-ga';
import { useEffect } from 'react';

const BeginnerBatch = () => {

  return (
    <>
      <ScrollToTop />
      <NavbarBatches/>
      <Batches {...beginnerObj}/>
      <Whatsappfooter />
      <Footer/>
    </>
  )
}

export default BeginnerBatch