import styled from 'styled-components';

export const InfoContainer = styled.div`
 color: #fff;
 background: #f0fff1;
 padding-top: 50px;

 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 

 @media screen and (max-width: 968px) {
  height: 1100px;
 }
`
export const InfoWrapper = styled.div`
 display: grid;
 z-index: 1;
 height: 860px;
 width: 100%auto;
 max-width: 1100px;
 margin-right: auto;
 margin-left: auto;
 padding: 0 24px;
 justify-content: center;
`
export const TitleHeading = styled.h1`
 font-size: 2.5rem;
 line-height: 1.1;
 display: flex;
 font-weight: 600;
 justify-content: center;
 color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

 @media screen and (max-width: 480px) {
  font-size: 32px;
 }
`
export const InfoRow = styled.div`
 display: grid;
 grid-auto-columns: minmax(auto, 1fr);
 align-items: center;
 grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

 @media screen and (max-width: 968px) {
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}
 }
`
export const Column1 = styled.div`
 margin-bottom: 15px;
 padding: 0 15px;
 grid-area: col1;
`
export const Column2 = styled.div`
 margin-bottom: 15px;
 padding: 0 15px;
 grid-area: col2;
`
export const TextWrapper = styled.div`
 max-width: 540px;
 padding-top: 0;
 padding-bottom: 60px;
 align-items: center;
`
export const TopLine = styled.p`
 color: #01bf71;
 font-size: 30px;
 line-height: 16px;
 font-weight: bold;
 letter-spacing: 1.4px;
 text-transform: uppercase;
 margin-bottom: 60px;
`
export const Subtitle  = styled.p`
 max-width: 440px;
 margin-top: 35px;
 margin-bottom: 35px;
 font-size: 18px;
 line-height: 24px;
 color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const ImgWrap = styled.div`
 max-width: 555px;
 height: 100%;
 @media screen and (max-width: 968px) {
  display: none;
 }
`;
export const Img = styled.img`
 width: 100%;
 margin: 0 0 10px 0;
 padding-right: 0;
`;
export const Heading = styled.h1`
 margin-bottom: 14px;
 margin-top: 74px;
 font-size: 28px;
 line-height: 1.1;
 font-weight: 600;
 color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

 @media screen and (max-width: 480px) {
  font-size: 32px;
 }
`
export const ContactUsContainer = styled.div`
 height: 700px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background: #010606;

 @media screen and (max-width: 1000px) {
  height: 1300px;
 }

 @media screen and (max-width: 968px) {
  height: 1800px;
 }

 @media screen and (max-width: 480px) {
  height: 1700px;
 }
`